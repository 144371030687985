import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  static targets = ["project", "scroller"]

  connect() {
    for (const project of this.projectTargets) {
      useIntersection(this, {
        root: this.element,
        element: project,
        visibleAttribute: "data-visible",
        threshold: [0.01, 0.1, 0.2, 0.5, 1.0],
        dispatchEvent: false
      })
    }
  }

  appear() {
    this.#toggleCurrent()
  }

  disappear() {
    this.#toggleCurrent()
  }

  slideToStart() {
    this.scrollerTarget.scrollTo({
      left: 0,
      behavior: "smooth"
    })
  }

  #toggleCurrent() {
    const current = this.projectTargets.find(
      (project) => project.dataset.visible === "true"
    )
    this.projectTargets.forEach((project) => {
      project.dataset.current = project === current
    })
    this.dispatch("current", { detail: { current }, prefix: "project" })
  }
}
