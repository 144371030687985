import smoothScrollIntoView from "smooth-scroll-into-view-if-needed"

export function smoothScrollIntoViewWithPromise(element, scrollable, options) {
  const scrollListener =
    scrollable === document.documentElement ? window : scrollable

  const canScroll = willScroll(element, scrollable, options)

  if (canScroll) {
    return createScrollPromise(scrollListener, () => {
      smoothScrollIntoView(element, options)
    })
  } else {
    return Promise.resolve()
  }
}

export function createScrollPromise(scrollListener, scrollFn) {
  const SCROLL_TIMEOUT = 100

  return new Promise((resolve) => {
    let scrollTimeoutId

    const handleScroll = () => {
      window.clearTimeout(scrollTimeoutId)
      scrollTimeoutId = setTimeout(() => {
        scrollListener.removeEventListener("scroll", handleScroll)
        resolve()
      }, SCROLL_TIMEOUT)
    }

    scrollListener.addEventListener("scroll", handleScroll)
    scrollFn()
  })
}

export function willScroll(element, scrollable, options) {
  let scrollLeft = scrollable.scrollLeft !== element.offsetLeft
  const scrollTop = scrollable.scrollTop !== element.offsetTop

  if (options?.scrollMode === "if-needed") {
    const screenWidth = window.innerWidth
    scrollLeft =
      element.offsetLeft < scrollable.scrollLeft ||
      element.offsetLeft > scrollable.scrollLeft + screenWidth
  }

  return scrollLeft || scrollTop
}
