import { Controller } from "@hotwired/stimulus"
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed"
import { createScrollPromise } from "~/helpers/scroll_with_promise"

export default class extends Controller {
  static targets = ["scroller"]

  openProject(event) {
    event.preventDefault()
    const project = this.#getProjectFromLink(event.target)
    console.log(project)
    this.#toggleImageLoading(false)
    this.#scrollTo(project, this.scrollerTarget).then(() => {
      this.dispatch("open", { detail: { project } })
      this.#toggleImageLoading(true)
    })
  }

  projectLoaded() {
    this.#toggleImageLoading(true)
  }

  #scrollTo(
    element,
    scrollable,
    options = { behavior: "smooth", inline: "start" }
  ) {
    const canScroll = this.#shouldScroll(element, scrollable, options)

    if (canScroll) {
      return createScrollPromise(scrollable, () => {
        smoothScrollIntoView(element, options)
      })
    }
    return Promise.resolve()
  }

  #shouldScroll(element, scrollable, options) {
    let scrollLeft = scrollable.scrollLeft !== element.offsetLeft
    const scrollTop = scrollable.scrollTop !== element.offsetTop

    if (options?.scrollMode === "if-needed") {
      const screenWidth = window.innerWidth
      scrollLeft =
        element.offsetLeft < scrollable.scrollLeft ||
        element.offsetLeft > scrollable.scrollLeft + screenWidth
    }

    return scrollLeft || scrollTop
  }

  #toggleImageLoading(on) {
    if (on) {
      const lazyloadImages =
        this.scrollerTarget.querySelectorAll("img._lazyload")
      lazyloadImages.forEach((image) => {
        image.classList.remove("_lazyload")
        image.classList.add("lazyload")
      })
    } else {
      const lazyloadImages =
        this.scrollerTarget.querySelectorAll("img.lazyload")
      lazyloadImages.forEach((image) => {
        image.classList.remove("lazyload")
        image.classList.add("_lazyload")
      })
    }
  }

  #getProjectFromLink(link) {
    const insideTurboFrame = link.closest("turbo-frame")
    if (insideTurboFrame) {
      return insideTurboFrame
    } else {
      return document.querySelector(`#${event.target.dataset.turboFrame}`)
    }
  }
}
