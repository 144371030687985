import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { projectId: String }
  static classes = ["highlighted"]

  toggleHighlight(event) {
    const { current } = event.detail
    this.element.classList.toggle(
      this.highlightedClass,
      current.id == this.projectIdValue
    )
  }
}
