import { Controller } from "@hotwired/stimulus"
import { smoothScrollIntoViewWithPromise } from "~/helpers/scroll_with_promise"
import gsap from "gsap"

export default class extends Controller {
  static values = { open: Boolean }
  static targets = ["imagesContainer", "visibleImages", "toggleableImages"]

  static ANIMATION_CONFIG = {
    OPEN_DURATION: 1.0,
    CLOSE_DURATION: 0.5,
    EASE: "sine.inOut"
  }

  connect() {
    this.#hideToggleableImages()
  }

  toggle() {
    this.openValue = !this.openValue
  }

  onProjectLoaderOpen(event) {
    if (event.detail.project === this.element) {
      this.openValue = true
    }
  }

  openValueChanged(open) {
    if ((this.element.dataset.open === "true") === open) return
    this.element.dataset.open = open
    open ? this.#animateOpen() : this.#animateClose()
  }

  #animateOpen() {
    this.#setOverflowHidden()
    gsap.set(this.toggleableImagesTarget, { display: "flex" })
    gsap.fromTo(
      this.imagesContainerTarget,
      { width: this.visibleImagesTarget.offsetWidth },
      {
        width: this.#calculateExpandedWidth(),
        duration: this.constructor.ANIMATION_CONFIG.OPEN_DURATION,
        ease: this.constructor.ANIMATION_CONFIG.EASE,
        onComplete: () => this.#resetStyles()
      }
    )
  }

  #animateClose() {
    this.#setOverflowHidden()
    this.#scrollToClosedState().then(() => {
      gsap.to(this.imagesContainerTarget, {
        width: this.visibleImagesTarget.offsetWidth,
        duration: this.constructor.ANIMATION_CONFIG.CLOSE_DURATION,
        ease: this.constructor.ANIMATION_CONFIG.EASE,
        onComplete: () => {
          this.#resetStyles()
          this.#hideToggleableImages()
        }
      })
    })
  }

  #calculateExpandedWidth() {
    return (
      this.visibleImagesTarget.offsetWidth +
      this.toggleableImagesTarget.offsetWidth
    )
  }

  #setOverflowHidden() {
    gsap.set(this.toggleableImagesTarget, { overflow: "hidden" })
  }

  #resetStyles() {
    gsap.set(this.toggleableImagesTarget, { overflow: null })
    gsap.set(this.imagesContainerTarget, { width: "auto" })
  }

  #hideToggleableImages() {
    gsap.set(this.toggleableImagesTarget, { display: "none" })
  }

  #scrollToClosedState() {
    const lastImage = [...this.visibleImagesTarget.children].at(-1)
    return smoothScrollIntoViewWithPromise(
      lastImage,
      this.element.parentElement,
      {
        behavior: "smooth",
        inline: "start",
        scrollMode: "if-needed"
      }
    )
  }
}
