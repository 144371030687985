import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { overlayOpen: Boolean }
  static targets = ["overlayBlock", "overlayButton"]

  openOverlay(event) {
    const id = event.params.id
    this.overlayBlockTargets.forEach((block) => {
      block.setAttribute("aria-hidden", block.id !== id)
    })
    this.overlayButtonTargets.forEach((button) => {
      button.setAttribute("aria-selected", event.target === button)
    })
    this.overlayOpenValue = true
  }

  closeOverlay() {
    this.overlayOpenValue = false
    this.overlayButtonTargets.forEach((button) => {
      button.setAttribute("aria-selected", false)
    })
  }

  home() {
    this.dispatch("home")
    this.closeOverlay()
  }
}
