import { Controller } from "@hotwired/stimulus"
import { Idiomorph } from "idiomorph/dist/idiomorph.esm.js"

export default class extends Controller {
  connect() {
    this.element.addEventListener(
      "turbo:before-frame-render",
      this.onBeforeFrameRender
    )
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:before-frame-render",
      this.onBeforeFrameRender
    )
  }

  onBeforeFrameRender = (event) => {
    event.detail.render = (currentElement, newElement) => {
      const currentElementWidth = currentElement.getBoundingClientRect().width
      newElement.style.width = currentElementWidth + "px"
      newElement.dataset.visible = "true"
      currentElement.classList.add("morphing")
      newElement.classList.add("morphing")
      this.element.classList.add("morphing")
      Idiomorph.morph(currentElement, newElement, {
        callbacks: {
          beforeNodeMorphed: (oldNode, newNode) => {
            if (
              oldNode &&
              oldNode.classList &&
              oldNode.classList.contains("no-morph")
            ) {
              return false
            } else {
              return true
            }
          }
        }
      })
      currentElement.style.width =
        currentElement.firstChild.getBoundingClientRect().width + "px"
      currentElement.classList.remove("morphing")
      setTimeout(() => {
        currentElement.style.width = null
      }, 1000)
    }
  }
}
