import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    friction: { type: Number, default: 0.95 }
  }

  connect() {
    if (this.isTouchDevice) return
    this.isDown = false
    this.startX = 0
    this.scrollLeft = 0
    this.velocity = 0
    this.lastX = 0
    this.momentumFrame = null
    this.#setStyling()
    this.#addEventListeners()
  }

  disconnect() {
    this.#removeEventListeners()
    this.#resetStyling()
    cancelAnimationFrame(this.momentumFrame)
  }

  onMouseDown = (event) => {
    this.isDown = true
    this.startX = event.pageX - this.element.offsetLeft
    this.scrollLeft = this.element.scrollLeft
    this.lastX = this.startX
    this.velocity = 0
    cancelAnimationFrame(this.momentumFrame)
    this.element.style.cursor = "grabbing"
  }

  onMouseMove = (event) => {
    if (!this.isDown) return
    event.preventDefault()
    const x = event.pageX - this.element.offsetLeft
    const walk = x - this.startX
    this.element.scrollLeft = this.scrollLeft - walk
    this.velocity = x - this.lastX
    this.lastX = x
  }

  onMouseUp = () => {
    this.isDown = false
    this.element.style.cursor = "grab"
    this.#applyMomentum()
  }

  onMouseLeave = () => {
    this.isDown = false
    this.element.style.cursor = "grab"
    this.#applyMomentum()
  }

  get isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0
  }

  #setStyling() {
    this.originalUserSelectStyle = this.element.style.userSelect
    this.originalCursorStyle = this.element.style.cursor
    this.element.style.userSelect = "none"
    this.element.style.cursor = "grab"
  }

  #resetStyling() {
    this.element.style.userSelect = this.originalUserSelectStyle
    this.element.style.cursor = nullthis.originalCursorStyle
  }

  #addEventListeners() {
    this.element.addEventListener("mousedown", this.onMouseDown)
    this.element.addEventListener("mousemove", this.onMouseMove)
    this.element.addEventListener("mouseup", this.onMouseUp)
    this.element.addEventListener("mouseleave", this.onMouseLeave)
  }

  #removeEventListeners() {
    this.element.removeEventListener("mousedown", this.onMouseDown)
    this.element.removeEventListener("mousemove", this.onMouseMove)
    this.element.removeEventListener("mouseup", this.onMouseUp)
    this.element.removeEventListener("mouseleave", this.onMouseLeave)
  }

  #applyMomentum() {
    const step = () => {
      if (Math.abs(this.velocity) > 0.1) {
        this.element.scrollLeft -= this.velocity
        this.velocity *= this.frictionValue
        this.momentumFrame = requestAnimationFrame(step)
      } else {
        cancelAnimationFrame(this.momentumFrame)
      }
    }
    step()
  }
}
